<template>
	<div
		class="h-screen flex w-full bg-img vx-row no-gutter items-center justify-center"
		id="page-login"
	>
	<div class="vx-col sm:w-1/1 xs:w-1/1 lg:w-1/1 sm:m-0 m-4" v-if="windowWidth > 600">
		<h1 v-if="isVerifying"> {{ message }} </h1>
			<img src="@/assets/images/logo/koblatil.png" alt="Kobla" class="pr-5 w-1/3" v-if="errorView"/>
			<vx-card class="auth-card" v-if="errorView">
				<div slot="no-body" class="full-page-bg-color">
					<div class="vx-row no-gutter justify-center items-center">
						<div class="vx-col sm:w-full xs:w-full lg:w-full d-theme-dark-bg">
							<h4 class="px-8 pt-8 login-tabs-container">
							{{$t('Your token is invalid')}}, <span class="text-blue pointer" @click="loginUser">{{$t('try to log in normally')}}</span>
							</h4>
						</div>
					</div>
				</div>
			</vx-card>
		</div>
		<div class="vx-col sm:w-1/1 xs:w-1/1 lg:w-1/1 sm:m-0 m-4" v-if="windowWidth < 600">
			<h1 v-if="isVerifying"> {{ message }} </h1>
			<img src="@/assets/images/logo/koblatil.png" alt="Kobla" class="pr-5 w-1/3" v-if="errorView"/>
			<vx-card class="auth-card" v-if="errorView">
				<div slot="no-body" class="full-page-bg-color">
					<div class="vx-row no-gutter justify-center items-center">
						<div class="vx-col sm:w-full xs:w-full lg:w-full d-theme-dark-bg">
							<h4 class="px-8 pt-8 login-tabs-container">
							{{$t('Your token is invalid')}}, <span class="text-blue pointer" @click="loginUser">{{$t('try to log in normally')}}</span>
							</h4>
						</div>
					</div>
				</div>
			</vx-card>
		</div>
		<modal-reset-password
			:show="isShowResetPassword"
			@redirectLink="redirectLink">
		</modal-reset-password>
	</div>
</template>

<script>
import { mapGetters } from 'vuex';
import ModalResetPassword from '@/components/modals/ModalResetPassword.vue';
import { getAxiosErrorMessage } from '@/lib/helper';
import authApi from '../../../api/auth';


export default {
  created() {
    this.autoLogin();
  },
  data() {
    return {
      windowWidth: window.innerWidth,
      message: 'we\'re verifying your data..',
      isFailed: false,
      isVerifying: false,
      errorView: false,
      isShowResetPassword: false,
    };
  },
  components: {
    ModalResetPassword,
  },
  methods: {
    autoLogin() {
      this.isVerifying = true;
      if (!this.checkLogin()) return;
      const token = this.$route.query.token;
      const slug = this.$route.query.slug;
      const params = {
        token,
      };
      if (slug) params.slug = slug;
      const callback = (response) => {
        const params = response.data;
        params.is_redirect = false;
        this.$store.dispatch('loginAuth', params);
        const redirectLink = response.data.redirect;
        this.link = redirectLink;
        const message = response.message;
        this.$vs.notify({
          title: 'Auto Login',
          text: message,
          iconPack: 'feather',
          icon: 'icon-alert-circle',
          color: 'success',
        });
        this.checkSetPassword(response.data);
        this.isVerifying = false;
      };
      const usersErrorCallback = (e) => {
        const message = getAxiosErrorMessage(e);
        this.$vs.notify({
          title: 'SMS Code',
          text: message,
          iconPack: 'feather',
          icon: 'icon-alert-circle',
          color: 'danger',
        });
        this.errorView = true;
        this.isVerifying = false;
      };
      authApi.verifyUser(params, callback, usersErrorCallback);
    },
    checkSetPassword(data) {
      if (data.is_set_password) {
        this.isShowResetPassword = true;
      } else {
        this.redirectLink();
      }
    },
    checkLogin() {
      // If user is already logged in notify
      // if (this.$store.state.auth.isUserLoggedIn()) {
      if (this.$store.state.isLoggedIn) {
        // Close animation if passed as payload
        // this.$vs.loading.close()

        this.$vs.notify({
          title: 'Login Attempt',
          text: 'You are already logged in!',
          iconPack: 'feather',
          icon: 'icon-alert-circle',
          color: 'warning',
        });

        return false;
      }
      return true;
    },
    loginUser() {
      this.$router.push('/login').catch(() => {});
    },
    redirectLink() {
      setTimeout(() => {
        this.message += 'redirecting ...';
        window.location = this.link;
      }, 200);
    },
  },
  mounted() {
    window.onresize = () => {
      this.windowWidth = window.innerWidth;
    };
  },
  computed: {
    ...mapGetters({
      loginError: 'loginError',
    }),
  },
  watch: {
    loginError(message) {
      if (message && message !== '') {
        this.$vs.notify({
          title: 'Login Error',
          text: message,
          iconPack: 'feather',
          icon: 'icon-alert-circle',
          color: 'danger',
        });
      }
    },
  },
};
</script>

<style lang="scss">
.auth-card{
	min-width: 470px;
}
.auth-mobile{
	min-width: 300px;
}
.title-auth{
	color: #4690DE;
	cursor: pointer;
}
.login-tabs-container {
	min-height: 80px;

	.con-tab {
		padding-bottom: 14px;
	}

	.con-slot-tabs {
		margin-top: 1rem;
	}
}
</style>
