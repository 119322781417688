<template>
	<modal size="medium" :color-mask="'black'" :hide-footer="false" :show-close="false" @close="close" v-if="show">
		<div slot="header">
			<h5 class="modal-title">{{ $t('Set Password') }}</h5>
		</div>
		<div slot="body">
			<div class="row">
				<div class="col-md-12">
					<p>
						{{ $t('This is your first time login, please set your new password') }}
					</p>
					<!-- new password -->
					<div class="mt-10 mb-5">
						<div class="vx-col w-full mb-2">
							<!-- New Password -->
							<vs-input v-filter-input
								:label-placeholder="$t('New Password')"
								v-model="new_password"
								class="mt-8 w-full"
								name="password"
								v-validate="'min:6'"
								type="password"
							/>
							<span
								class="text-danger text-sm"
								v-show="errors.has('New Password')"
							>{{ $t(errors.first('password')) }}</span>
						</div>
					</div>
					<!-- confirm password -->
					<div class="vx-row">
						<div class="vx-col w-full mb-2">
							<!-- Confirm Password -->
							<vs-input v-filter-input
								:label-placeholder="$t('Confirm Password')"
								v-model="confirm_password"
								class="mt-8 w-full"
								name="confirm-password"
								v-validate="'min:6'"
								type="password"
							/>
							<span
								class="text-danger text-sm"
								v-show="errors.has('Confirm Password')"
							>{{ $t(errors.first('confirm-password')) }}</span>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div slot="footer">
			<div class="modal-action text-right">
				<vs-button type="relief" :class="{ 'is-loading': isSubmitting }" @click="changePassword()">{{ $t('Submit') }}</vs-button>
			</div>
		</div>
	</modal>
</template>

<script>

import { mapGetters } from 'vuex';
import authApi from '@/api/auth';
import { getAxiosErrorMessage } from '@/lib/helper';
import Modal from '@/components/Modal.vue';

export default {
  name: 'ModalResetPassword',
  components: {
    Modal,
  },
  props: {
    show: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isSubmitting: false,
      confirm_password: '',
      new_password: '',
    };
  },
  computed: {
    ...mapGetters({
      isLoggedIn: 'isLoggedIn',
      user: 'user',
    }),
    params() {
      const params = {
        new_password: this.new_password,
        confirm_password: this.confirm_password,
      };
      return params;
    },
  },
  methods: {
    close() {
      this.$emit('close');
    },
    changePassword() {
      this.isSubmitting = true;
      const params = this.params;
      const callback = (response) => {
        const message = response.message;
        this.$emit('redirectLink');
        this.$vs.notify({
          title: this.$t('User'),
          text: message,
          iconPack: 'feather',
          icon: 'icon-alert-circle',
          color: 'success',
        });
        this.isSubmitting = false;
      };
      const errorCallback = (e) => {
        const message = getAxiosErrorMessage(e);
        this.$vs.notify({
          title: this.$t('User'),
          text: message,
          iconPack: 'feather',
          icon: 'icon-alert-circle',
          color: 'danger',
        });
        this.isSubmitting = false;
      };
      authApi.changePassword(params, callback, errorCallback);
    },

  },
  mounted() {
  },
  created() {
  },
  destroyed() {
  },
};
</script>

<style lang="scss">
.code-input {
    width: 40px;
    height: 40px;
    padding: 5px;
    margin: 0 10px;
    font-size: 20px;
    border-radius: 4px;
    border: 1px solid rgba(0, 0, 0, 0.3);
    text-align: center;
    &.error {
		border: 1px solid red !important;
    }
}
.code-input::-webkit-inner-spin-button,
.code-input::-webkit-outer-spin-button {
	-webkit-appearance: none;
	margin: 0;
}
</style>
